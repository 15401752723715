<template>
	<div>
		<CCard class="p-4">
		<CRow>
			
			<CCol lg="6">
					<h5>SSS Table Contributions</h5>					
			</CCol>
	     		 <CCol lg="6">	
					<div style="text-align: right">  
						<CButton class="mr-1" color="btn btn-info" size="sm" @click="print">
							<i class="fa fa-print"></i> Print
						</CButton> 					  
						<CButton 
							v-if="config.getPermission('sss').download"
							size="sm" style="margin-right: 10px" 
							color="btn btn-outline-primary" @click="download()" 
							class="float-lg-right">
							<i class="fa fa-download"></i> Download
						</CButton>
						
					</div>
			    </CCol>
		</CRow>
		
		<hr>

		<CRow>			
	       <CCol lg="12" class="text-center" v-if="isLoading"> 
				  <div class="spinner-border m-5" role="status">
						<span class="sr-only">Loading...</span>
					</div>
			</CCol>
		<CCol lg="12" v-else>  
			
			<div class="table-responsive-sm table-responsive-md">
	      		<table class="table table-bordered table-sm text-center">
					<thead style="font-weight:bold">
						<tr>
							<td rowspan="2" style="vertical-align: middle;">Range of Compensation</td>
							<td colspan="3">SSS</td>
							<td>EC</td>
							<td colspan="3">TOTAL</td>
						</tr>
						<tr>
							<td>EE</td>
							<td>ER</td>
							<td>TOTAL</td>
							<td>ER</td>
							<td>EE</td>
							<td>ER</td>
							<td>CONT</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) of contribution_table" :key="index">
							<td>{{item.range | number_format(false, 2)}}</td>
							<td>{{item.sss.ee | number_format(false, 2)}}</td>							
							<td>{{item.sss.er | number_format(false, 2)}}</td>
							<td>{{item.sss.total | number_format(false, 2)}}</td>
							<td>{{item.ec.er | number_format(false, 2)}}</td>
							<td>{{item.total.ee | number_format(false, 2)}}</td>
							<td>{{item.total.er | number_format(false, 2)}}</td>
							<td>{{item.total.cont | number_format(false, 2)}}</td>
						</tr>
					</tbody>
				</table>
			</div>

			
	      	</CCol>
	    </CRow> 
		</CCard>
		<Print :dataList="this.dataList"/>
	</div>
</template>
<script> 
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'; 
import Print from './print';
import moment from 'moment';

export default {
	mounted(){		 		
		this.getContributionTable()
	
	},
	filters: {
	},
	watch: {
		
	},
	computed: {
		
	},
	data(){
		return{
			contribution_table: [],			
			isLoading: false,
			config,
			dataList: [],  
			currentPage: 1,  
			showCollapse: false,																		
		}
	},
	name: 'Tables',
	components: { Datepicker, vSelect, Print},
	methods: {
		getContributionTable() {
			this.$showLoading(true)
			axios.get(config.api_path+'/sss/contribution/table').then(res => {
				const {data, status} = res.data;
				this.dataList = data;				
				this.contribution_table = data.map(item => {
					// item['sss'] => item.
					return item;
				});
			})
			this.$showLoading(false)
		},
		download() {
			this.$showLoading(true)
			axios.get(config.api_path+'/sss/contribution/download')
			.then(response => {
				this.$showLoading(false)
				window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file) 

					setTimeout(() => {
						axios.get(`${config.api_path}/remove-file`,{
							params : {
								filename : response.data.file
							}
						})	
					}, 2000);
				}).catch(err => {
				this.$showLoading(false)
			})
		},

		
		
		reloadData(){
			this.getData();  
		},
		print(){

	    	var mywindow = window.open('', 'PRINT');

		    mywindow.document.write('<html><head>');
		    mywindow.document.write('<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css" integrity="sha384-KyZXEAg3QhqLMpG8r+8fhAXLRk2vvoC2f3B09zVXn8CA5QIVfZOJ3BCsw2P0p/We" crossorigin="anonymous">')
		    mywindow.document.write('<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css">')
		    mywindow.document.write('</head><body >'); 
		    mywindow.document.write(document.getElementById('month_print').innerHTML);
		    mywindow.document.write('</body></html>');

		    mywindow.document.close(); // necessary for IE >= 10
		    mywindow.focus(); // necessary for IE >= 10*/

		    mywindow.print();
		    mywindow.close();
	    },
		
	   

}}
</script>
