<template>
  <div
    class="content-container print"
    style="overflow: hidden"
    id="month_print"
  >
    <div class="table-responsive-sm table-responsive-md">
      <table class="table table-bordered table-sm text-center" border="1" style="text-align: center !important; border: 1px solid; border-color: #d8dbe0; width: 100%; margin-bottom: 1rem; color: #3c4b64; border-collapse: collapse;">
        <thead>
          <tr>
            <td rowspan="2" style="vertical-align: middle;">
              Range of Compensation
            </td>
            <td colspan="3">SSS</td>
            <td>EC</td>
            <td colspan="3">TOTAL</td>
          </tr>
          <tr>
            <td>EE</td>
            <td>ER</td>
            <td>TOTAL</td>
            <td>ER</td>
            <td>EE</td>
            <td>ER</td>
            <td>CONT</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) of dataList" :key="index">
            <td>{{ item.range | number_format(false, 2) }}</td>
            <td>{{ item.sss.ee | number_format(false, 2) }}</td>
            <td>{{ item.sss.er | number_format(false, 2) }}</td>
            <td>{{ item.sss.total | number_format(false, 2) }}</td>
            <td>{{ item.ec.er | number_format(false, 2) }}</td>
            <td>{{ item.total.ee | number_format(false, 2) }}</td>
            <td>{{ item.total.er | number_format(false, 2) }}</td>
            <td>{{ item.total.cont | number_format(false, 2) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<style scoped>
@page {
  size: auto;
  margin: 0mm;
}
.test {
  color: blue;
}
@media print {
  .col-special-1,
  .col-special-2,
  .col-special-3,
  .col-special-4,
  .col-special-5,
  .col-special-6,
  .col-special-7,
  .col-special-8,
  .col-special-9,
  .col-special-10,
  .col-special-11,
  .col-special-12 {
    float: left;
  }

  .col-special-12 {
    width: 100%;
  }

  .col-special-11 {
    width: 91.66666666666666%;
  }

  .col-special-10 {
    width: 83.33333333333334%;
  }

  .col-special-9 {
    width: 75%;
  }

  .col-special-8 {
    width: 66.66666666666666%;
  }

  .col-special-7 {
    width: 58.333333333333336%;
  }

  .col-special-6 {
    width: 50%;
  }

  .col-special-5 {
    width: 41.66666666666667%;
  }

  .col-special-4 {
    width: 33.33333333333333%;
  }

  .col-special-3 {
    width: 25%;
  }

  .col-special-2 {
    width: 16.666666666666664%;
  }

  .col-special-1 {
    width: 8.333333333333332%;
  }
}
</style>

<script>
import moment from "moment";
export default {
  mounted() {},
  props: {
    details: {
      booking_no: "",
      equipment: "",
      vehicle_type_id: "",
      maker_model_id: "",
      origin_id: "",
      vendor_id: "",
      driver_id: "",
      body_no: "",
      year: "",
      with_gps: "",
      company_owned: true,
      plate_no: "",
      registration_date: "",
      asset_no: "",
      engine_no: "",
      engine_size: "",
      chassis_no: "",
      transmission_type: "",
      transfer_date: "",
      color: "",
      fuel_type: "",
      later_renewal_date: "",
      acquisition_date: "",
      acquisition_price: "",
      remarks: "",
      vehicle_photo: "",
      or_photo: "",
      cr_photo: "",
      insurance_photo: "",
      status: "active",
      location: "",
    },
    dataList: [],
  },
  data() {
    return {
      moment,
    };
  },
};
</script>
